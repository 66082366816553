import React from "react";
import "./about.css";
import ME from "../../assets/about_img.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import { useState } from "react";

function About() {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about-container ">
        {/* <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div> */}

        <div className="about_content">
          <div className="about_cards">
            <a
              href="#experience"
              onClick={() => setActiveNav("#experience")}
              className={activeNav === "#experience" ? "active" : ""}
            >
              <article className="about-card card">
                <FaAward className="about_icon" />
                <h5>Experience</h5>
                <small>6+ Years Total | 1 Year Full Stack</small>
              </article>
            </a>
            {/* <article className="about-card">
                <FiUsers className="about_icon" />
                <h5>Clients</h5>
                <small>2 Worldwide</small>
              </article> */}
            <a
              href="#portfolio"
              onClick={() => setActiveNav("#portfolio")}
              className={activeNav === "#portfolio" ? "active" : ""}
            >
              <article className="about-card card">
                <VscFolderLibrary className="about_icon" />
                <h5>Projects</h5>
                <small>10+</small>
              </article>
            </a>
          </div>
          <p className="about-description">
            I am a passionate full-stack software engineer from New York with
            experience in JavaScript, React, and Python. With a keen eye for
            detail, I have been involved in all stages of the product life
            cycle, ensuring software quality through rigorous testing practices.
            I am motivated, adaptable, and enjoy exploring the latest software
            development practices and testing methodologies. I strongly believe
            in collaboration and teamwork to deliver successful software
            projects.
          </p>
          <div className="about-btn">
            <a href="#contact" className="btn btn-primary about-btn">
              Let's Talk
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
