import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/avatar1.png";
import AVTR2 from "../../assets/avatar2.png";
import AVTR3 from "../../assets/ryan.jpeg";
// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    avatar: AVTR1,
    name: "Marina Ismailova",
    review:
      "I was Anna's mentor. As a specialist with more than 11 years of experience in the software engineering, I can say that any team will be happy to work with Anna and receive a dedicated, responsible colleague. Anna learns everything quickly and does not stop at difficulties. Anna, you are one of the best, I believe in your success!💪",
  },
  {
    avatar: AVTR2,
    name: "Megan Hawkins",
    review:
      "Anna is an incredibly talented student whom I had the pleasure of working with during her time as a student at General Assembly. She's a very hard-working individual whose dedication during the course allowed her to produce exceptional work that showcased how she mastered the topics of study. She went above and beyond in the course to gain as much knowledge and skill to become a successful software engineer.Moreover, she consistently brought a very positive attitude to class and was willing to help others who were stuck. Also, in the group project work, she took on a leadership role, effectively helping to manage her team in creating a great application. Any company would be lucky to add a hard-working and talented individual like Anna.",
  },
  {
    avatar: AVTR3,
    name: "Ryan Ehrlich",
    review:
      "Anna is an exceptional developer. Her approach, attention to detail, work ethic, and dedication shine in every project she touches. I had the pleasure of collaborating with Anna on two full-stack projects, both of which would not have had the same outstanding results were it not for her creativity and ingenuity.Her ability to envision, communicate, and implement a professional responsive design are quite simply superior to anyone I know in the field. I highly recommend Anna and look forward to seeing all of her continued success!",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Feedback from Colleagues</h5>
      <h2>Feedback</h2>

      <Swiper
        className="testimonials_container container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="client_avatar">
                <img src={avatar} alt="avatar One" />
              </div>
              <h5 className="client_name">{name}</h5>
              <small className="client_review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
