import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/gashop.png";
import IMG2 from "../../assets/project1.png";
import IMG3 from "../../assets/space.png";
import IMG4 from "../../assets/project_4.png";
import IMG5 from "../../assets/alike.png";
import IMG7 from "../../assets/alike_new.png";
import IMG8 from "../../assets/art_new.png";
import IMG6 from "../../assets/cla.png";

const data = [
  {
    id: 1,
    image: IMG1,
    title: "GA Express E-commerce website Project",
    github: "https://github.com/annadruzhinina/FrontEnd-Shop",
    demo: "https://gaexpress.netlify.app/",
  },
  {
    id: 2,
    image: IMG8,
    title: "Art Institute of Chicago Project",
    github: "https://github.com/annadruzhinina/api-ui-pattern-art",
    demo: "https://rainbow-creponne-172444.netlify.app/",
  },
  {
    id: 3,
    image: IMG3,
    title: "Final Space UI/BackEnd API Project",
    github: "https://github.com/annadruzhinina/Space-Heroes",
    demo: "https://main--soft-snickerdoodle-027d72.netlify.app/",
  },
  {
    id: 4,
    image: IMG4,
    title: "Trivia Game Responsive UI Project",
    github: "https://github.com/annadruzhinina/Trivia-Game-UI",
    demo: "https://annadruzhinina.github.io/Trivia-Game-UI/",
  },
  {
    id: 5,
    image: IMG7,
    title: "Alike Social Media Platform Project",
    github: "https://github.com/annadruzhinina/FrontEnd-Alike",
    demo: "https://alike-ga.netlify.app/",
  },
  {
    id: 6,
    image: IMG6,
    title: "School Management CLI Project",
    github:
      "https://git.generalassemb.ly/annadruzhinina/python-command-line-project",
    demo: "https://git.generalassemb.ly/annadruzhinina/python-command-line-project",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio-container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio_item card">
              <div className="portfolio_item_image">
                <img className="portfolio-img" src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio_item_cta">
                <a href={github} className="btn" target="_blank">
                  Github
                </a>
                <a href={demo} className="btn btn-primary" target="_blank">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
