import React from "react";
import "./contact.css";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookMessenger } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { useRef } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_ptrwxsu",
      "template_aj712el",
      form.current,
      // "YOUR_PUBLIC_KEY"
      "HDwd_fMICDOSrEiIL"
    );
  };
  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact_container">
        <div className="contact_options">
          <a
            href="mailto:anna@rcbd.org?body=My custom mail body"
            target="_blank"
          >
            <article className="contact_option card">
              <AiOutlineMail className="contact_option-icon" />
              <h4>Email</h4>
              <h5>anna@rcbd.org</h5>
              Send a message
            </article>
          </a>
          <a href="https://m.me/profile.php?id=100010015683151" target="_blank">
            <article className="contact_option card">
              <FaFacebookMessenger className="contact_option-icon" />
              <h4>Messager</h4>
              <h5>Anna Druzhinina</h5>
              Send a message
            </article>
          </a>
          <a href="https://wa.me/15512217603" target="_blank">
            <article className="contact_option card">
              <BsWhatsapp className="contact_option-icon" />
              <h4>WhatsApp</h4>
              <h5></h5>
              Send a message
            </article>
          </a>
        </div>
        {/* End of Contact Options */}
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="name" placeholder="Your Email" required />
          <textarea
            type="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
