import React from "react";
import "./services.css";
import { BiCheck } from "react-icons/bi";
const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services_container ">
        {/* END UI/UX */}
        <article className="service card">
          <div className="service_head">
            <h3>FrontEnd Development</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                User Interface (UI) Development: Develop visually appealing and
                responsive user interfaces using HTML, CSS, and JavaScript.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Web Application Development: Develop web applications using
                frameworks such as React, Angular.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Mobile-first Development: Develop mobile-first and responsive
                websites and applications that are optimized for all devices.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Test and debug websites and applications to ensure they run
                smoothly and function correctly.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Version Control: Use version control tools such as Git to manage
                code changes and collaborate with other developers.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Responsive Design: Develop responsive designs that adapt to
                various screen sizes, resolutions, and devices.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Integration with APIs: Integrate websites and applications with
                APIs to add dynamic content and functionality.
              </p>
            </li>
          </ul>
        </article>
        {/* END WEB DEVELOPMENT */}
        <article className="service card">
          <div className="service_head">
            <h3>BackEnd Development</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                API Utilization: Utilize APIs developed using frameworks like
                Express, Django, or Flask to access and leverage data and
                functionality in frontend applications.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Database Management: Design, develop, and manage databases using
                relational databases like PostgreSQL and ORM libraries like
                Peewee.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Server-side Development: Develop server-side logic and
                infrastructure using Node.js or Python, ensuring high
                availability and scalability.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Cloud Deployment: Deploy applications to cloud platforms like
                Heroku,Railway or AWS to provide highly scalable and reliable
                services.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Security: Ensure the security of the application by implementing
                security protocols, encryption, and access control measures.
              </p>
            </li>
          </ul>
        </article>
        {/* END CONTENT*/}
        <article className="service card">
          <div className="service_head">
            <h3>Quality Assurance Engineer</h3>
          </div>
          <ul className="service_list">
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Quality assurance planning: Develop and implement quality
                assurance plans to ensure that products or services meet the
                necessary standards and specifications.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Test plan development: Create test plans and test cases to
                identify defects or issues in products or services.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Quality control implementation: Establish quality control
                measures to ensure that products or services are consistent and
                compliant with industry standards.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Process improvement: Identify areas for improvement in existing
                processes and systems, and develop solutions to improve
                efficiency and effectiveness.
              </p>
            </li>
            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Root cause analysis: Conduct root cause analysis to identify the
                underlying cause of quality issues and develop solutions to
                address them.
              </p>
            </li>

            <li>
              <BiCheck className="service_list-icon" />
              <p>
                Documentation and reporting: Create and maintain quality
                documentation, including test plans, test cases, and reports
              </p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
